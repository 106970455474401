import React, {SyntheticEvent} from 'react';
import {
  Box, IconButton, useMediaQuery, useTheme,
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import L, {LatLngBounds, LatLngBoundsLiteral, Map as LeafletMap} from 'leaflet';
import {useMap} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import {useSnackbar} from 'notistack';
import {RootState} from '../store/store';
import {openReachabilityDialog} from '../store/uiSlice';

const MapControlButtons = () => {
  const heatmapBoundingBox: LatLngBoundsLiteral | null = useSelector((state: RootState) => state.map.heatmapMetaData?.boundingBox || null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const map:LeafletMap = useMap();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClickZoomIn = (syntheticEvent:SyntheticEvent) => {
    syntheticEvent.stopPropagation();

    if (!map) {
      return;
    }
    map.zoomIn();
  };

  const handleClickZoomOut = (syntheticEvent:SyntheticEvent) => {
    syntheticEvent.stopPropagation();

    if (!map) {
      return;
    }
    map.zoomOut();
  };

  const handleClickShowAll = (syntheticEvent:SyntheticEvent) => {
    syntheticEvent.stopPropagation();

    if (!map || !heatmapBoundingBox) {
      return;
    }
    const bounds = new LatLngBounds(heatmapBoundingBox);
    const zoomLevel = map.getBoundsZoom(bounds) + 1;
    map.setView(bounds.getCenter(), zoomLevel);
    // map.fitBounds(new LatLngBounds(heatmapBoundingBox));
  };

  const handleLocateMe = (syntheticEvent:SyntheticEvent) => {
    syntheticEvent.stopPropagation();

    if (!map || !heatmapBoundingBox) {
      return;
    }

    map.locate({setView: false, watch: false})
      .once('locationfound', (e) => {
        const {latlng} = e;
        if (L.latLngBounds(heatmapBoundingBox).contains(latlng)) {
          map.flyTo(latlng, map.getZoom() < 12 ? 14 : undefined);
        } else {
          enqueueSnackbar('You are not in the requested area. Add your location manually.', {variant: 'error'});
          dispatch(openReachabilityDialog());
        }
      })
      .once('locationerror', (e) => {
        console.error(e);
      });
  };

  const buttonStyle = {
    backgroundColor: 'white',
    border: '1px solid #cdcdcd',
    borderRadius: '50%',
    marginX: 1.5,
    marginY: 0.6,
    padding: '9px',
    '&:hover': {
      backgroundColor: '#cdcdcd !important',
    },
  };
  const buttonIconStyle = {fontSize: {xs: '1.5rem', sm: '1.8rem'}};

  return (
    <Box sx={{
      position: 'absolute',
      right: {xs: 'inherit', sm: '2%'},
      left: {xs: '0', sm: 'inherit'},
      bottom: {xs: '44vh', sm: 'inherit'},
      flexDirection: {xs: 'column', sm: 'row'},
      zIndex: '999',
      display: 'flex',
      justifyContent: 'center',
    }}
    >
      <IconButton sx={buttonStyle} onClick={handleClickShowAll}>
        <CenterFocusStrongIcon sx={buttonIconStyle} />
      </IconButton>
      {isMobile && (
      <>
        <IconButton sx={buttonStyle} onClick={handleClickZoomOut}>
          <ZoomOutIcon sx={buttonIconStyle} />
        </IconButton>
        <IconButton sx={buttonStyle} onClick={handleClickZoomIn}>
          <ZoomInIcon sx={buttonIconStyle} />
        </IconButton>
      </>
      )}
      <IconButton sx={buttonStyle} onClick={handleLocateMe}>
        <LocationOnIcon sx={buttonIconStyle} />
      </IconButton>
      {/* <IconButton sx={buttonStyle} onClick={handleLocateMe}> */}
      {/*  {watchLocation */}
      {/*    ? (<GpsFixedIcon sx={{...buttonIconStyle, color: 'black' }} />) */}
      {/*    : (<GpsNotFixedIcon sx={buttonIconStyle} />)} */}
      {/* </IconButton> */}
    </Box>
  );
};

export default MapControlButtons;
