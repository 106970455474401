import React from 'react';
import {
  Box, Button,
  Grid, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {
  openReachabilityDialog,
  openSatisfactionDialog,
  openWelcomeDialog,
} from 'store/uiSlice';
import RoomIcon from '@mui/icons-material/Room';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import NavigationIcon from '@mui/icons-material/Navigation';
import LayersIcon from '@mui/icons-material/Layers';
import ChipPanel, {ChipBackgroundSx} from '../../../components/ChipPanel';
import Logo from '../../../components/Logo';
import {RootState} from '../../../store/store';

function SettingsButtons() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const mapFullscreen = useSelector((state: RootState) => state.ui.mapFullscreen);

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: { sm: 'flex'},
          }}
        >
          {(!mapFullscreen || !isMobile) && (
          // <Box marginRight="4px">
          //   <Box
          //     maxWidth="50px"
          //     sx={ChipBackgroundSx}
          //     onClick={() => {
          //       dispatch(openWelcomeDialog());
          //     }}
          //   >
          //     <Logo />
          //   </Box>
          // </Box>
            <Button
              onClick={() => {
                dispatch(openWelcomeDialog());
              }}
              color="inherit"
              sx={{
                ...ChipBackgroundSx, marginRight: '4px !important', paddingTop: '10px', paddingBottom: 0, width: '30px',
              }}
            >
              <Logo />
            </Button>
          )}
          <Button
            startIcon={<NavigationIcon />}
            onClick={() => {
              dispatch(openReachabilityDialog());
            }}
            color="inherit"
            sx={{...ChipBackgroundSx, marginRight: '4px !important'}}
          >
            Location
          </Button>
          <Button
            startIcon={<LayersIcon />}
            onClick={() => {
              dispatch(openSatisfactionDialog());
            }}
            color="inherit"
            sx={ChipBackgroundSx}
          >
            Layer
          </Button>
        </Box>
        {/* <ListItem */}
        {/*  button */}
        {/*  onClick={() => { */}
        {/*    dispatch(openSatisfactionDialog()); */}
        {/*  }} */}
        {/*  sx={{paddingLeft: '4px !important'}} */}
        {/* > */}
        {/*  <ListItemIcon sx={{minWidth: '30px !important'}}> */}
        {/*    <LocationCityIcon /> */}
        {/*  </ListItemIcon> */}
        {/*  <ListItemText primary="Umgebung" /> */}
        {/* </ListItem> */}
      </Grid>
    </Grid>
  );
}

export default SettingsButtons;
