import React, {useEffect} from 'react';
import {
  Box, Button, Drawer, Grid, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  LoadingHeatmapState,
  openReachabilityDialog,
  openWelcomeDialog,
} from 'store/uiSlice';
import {RootState} from 'store/store';
import {Route, Routes} from 'react-router-dom';
import MatchPage from '../pages/MatchPage';
import PlacesDetailsPage from '../pages/PlacesDetailsPage';
import Logo from '../components/Logo';
import LoadingHeatmapIndicator from './LoadingHeatmapIndicator';
import Startpage from '../pages/startpage/Startpage';
import {routes} from '../config/routeConfig';
import {attributionMap} from '../config/attributionConfig';
import MapSharePage from '../pages/MapSharePage';
import ChipPanel from '../components/ChipPanel';

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  drawerPaper: {
    borderRight: 'none',
    overflowX: 'hidden',
    width: '100%',
    backgroundColor: 'rgba(245,245,245,0.8)',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '490px',
      overflowY: 'hidden',
      backgroundColor: 'rgba(245,245,245,0)',
      pointerEvents: 'none',
    },
  },
  rootContainer: {
    marginBottom: (props) => (props.mapFullscreen ? ' 78vh' : '45vh'),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '490px',
      backgroundColor: 'rgba(250,250,250,0.01)',
      overflow: 'hidden !important',
      pointerEvents: 'none',
      marginBottom: () => ('0'),
    },
    width: '100%',
    padding: theme.spacing(1),
    cursor: 'default',
    borderRadius: '0 0 10px 0',

  },
}));

interface StyleProps {
  mapFullscreen: boolean;
}

interface BackgroundBoxProps {
  children: React.ReactNode;
}

const BackgroundBox = ({children}: BackgroundBoxProps) => (
  <Box bgcolor="rgba(245,245,245,0.89)" borderRadius="10px">
    {children}
  </Box>
);

function Sidebar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const loadingHeatmapState = useSelector((state: RootState) => state.ui.loadingHeatmapState);
  const mapFullscreen = useSelector((state: RootState) => state.ui.mapFullscreen);
  const dispatch = useDispatch();
  const classes = useStyles({mapFullscreen});

  const routerPath = window.location.pathname;
  if (routerPath === routes.Impressum.path) {
    dispatch(openWelcomeDialog());
    window.history.replaceState({}, '', routes.Startpage.path);
  }

  useEffect(() => {
    if (loadingHeatmapState === LoadingHeatmapState.Error) {
      dispatch(openReachabilityDialog());
    }
  }, [loadingHeatmapState]);

  const renderContent = () => {
    if (loadingHeatmapState !== LoadingHeatmapState.Finished && loadingHeatmapState !== LoadingHeatmapState.NotStarted) {
      return (
        <Routes>
          <Route
            path={routes.MapShare.path}
            element={(
              <BackgroundBox>
                <MapSharePage />
              </BackgroundBox>
                        )}
          />
          <Route
            index
            element={(
              <BackgroundBox>
                <LoadingHeatmapIndicator />
              </BackgroundBox>
                        )}
          />
          <Route element={(
            <BackgroundBox>
              <LoadingHeatmapIndicator />
            </BackgroundBox>
                    )}
          />
        </Routes>
      );
    }
    return (
      <Routes>
        <Route
          path={routes.MapShare.path}
          element={(
            <BackgroundBox>
              <MapSharePage />
            </BackgroundBox>
                    )}
        />
        <Route index element={<Startpage />} />
        <Route
          path={routes.PlaceDetails.path}
          element={(
            <BackgroundBox>
              <PlacesDetailsPage key={window.location.pathname} />
            </BackgroundBox>
                    )}
        />
        <Route
          path={routes.AroundMe.path}
          element={(
            <BackgroundBox>
              <PlacesDetailsPage key={window.location.pathname} />
            </BackgroundBox>
                    )}
        />
        <Route path={routes.MatchDetails.path} element={<MatchPage key={window.location.pathname} />} />
        <Route path={routes.Impressum.path} element={<Startpage />} />
      </Routes>
    );
  };

  return (
    <Drawer
      anchor="left"
      open
      variant="persistent"
      classes={{ paper: classes.drawerPaper }}
      sx={{'& > .MuiPaper-root': {marginTop: {xs: mapFullscreen ? '100vh' : '45vh', sm: 0}}}}
    >
      <Grid container className={classes.rootContainer}>
        <Grid item xs={12}>
          <Box sx={{
            overflowY: 'auto', borderRadius: 2, overflowX: 'hidden', height: {xs: 'inherit', sm: '98vh'}, pointerEvents: 'none',
          }}
          >
            <Box sx={{pointerEvents: 'all'}}>
              {renderContent()}
            </Box>
          </Box>
        </Grid>
        {/* <Box sx={{display: {xs: 'block', sm: 'none'}}}> */}
        {/*  <Grid item xs={12}> */}
        {/*    <Box display="flex" width="100%" justifyContent="start" marginTop={1}> */}
        {/*      <Button */}
        {/*        variant="text" */}
        {/*        onClick={() => dispatch(openWelcomeDialog())} */}
        {/*        sx={{textTransform: 'inherit', color: 'black'}} */}
        {/*      > */}
        {/*        <Typography variant="caption"> */}
        {/*          Hilfe | Impressum */}
        {/*        </Typography> */}
        {/*      </Button> */}
        {/*    </Box> */}
        {/*  </Grid> */}
        {/* </Box> */}
        {isMobile && (
        <Grid item xs={12}>
          <Box justifyContent="center" marginTop={7}>
            <Typography variant="caption">
              <div dangerouslySetInnerHTML={{__html: attributionMap}} />
            </Typography>
          </Box>
        </Grid>
        )}
      </Grid>
    </Drawer>
  );
}

export default Sidebar;
